.ui.button.search-button {
  background: @color-secondary-grey;

  font-weight: 600;
  font-family: source-sans-pro, "Source Sans Pro (OTF)", sans-serif;
  font-size: 1.375rem;
  color: @color-text-primary;
  letter-spacing: 0.020em;
}

.ui.button.search-again-button {
  background: @color-secondary-grey;
  color: @color-text-primary;
}