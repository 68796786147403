.ui.grid > .column.listing-container {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

.ui.grid > .column.facets-container {
  padding-right: 4.25rem;

  @media (max-width: @largeMonitorBreakpoint) and (min-width: @tabletBreakpoint) {
    padding-right: 2rem;
  }
}

.ui.grid > .column.search-all-link-container {
  padding-left: 2rem;
  margin-bottom: 1rem;
}

.ui.grid > .column.detail-annotation-column {
  @media (min-width: @largeMonitorBreakpoint) {
    &:not(:last-child) {
      padding-right: 2.5rem;
    }
    &:not(:first-child) {
      padding-left: 2.5rem;
    }
  }
}