.ui.input {
  width: 100%;
  
  & > input {
    border-radius: revert;
  }
}

.ui.action.input > .ui.button.search-button {
  border-radius: revert;
}